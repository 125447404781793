import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();
  return (
    smUp && (
      <Box sx={{display:"flex", justifyContent:"end", width:"100%", alignItems:"center", gap:"10px"}}>
        <Box>
          <LanguagePopover />
        </Box>
        <Typography sx={{fontSize:"13px"}}>
          {translate("login.dontHave")}&nbsp;? &nbsp;&nbsp;
          <a
            style={{
              color: "#ff9800",
              fontWeight: "bold",
              textDecoration: "none",
              fontSize:"13px"
            }}
            href={process.env.REACT_APP_WORD_PRESS_REGISTER}
          >
            {translate("login.getStarted")}
          </a>
        </Typography>
      </Box>
    )
  );
};

export default GetStartedTitle;
