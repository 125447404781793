import { Link, Typography, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import { PATH_AUTH } from "src/routes/paths";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";

const NoAccountSection = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();
  return (
    !smUp && (
<Box sx={{display:"flex", alignItems:"center", mt: 3}}>
      <Box>
      <LanguagePopover />
    </Box>
      <Typography sx={{fontSize:"13px"}}>
        {translate("login.dontHave")}&nbsp;? &nbsp;&nbsp;
        <a
          style={{
            color: "#ff9800",
            fontWeight: "bold",
            textDecoration: "none",
          }}
          href={process.env.REACT_APP_WORD_PRESS_REGISTER}
        >
          {translate("login.getStarted")}
        </a>
      </Typography>
      </Box>
    )
  );
};

export default NoAccountSection;
