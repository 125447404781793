// @mui
import { Stack, Button, Typography, Box } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import { PATH_DOCS } from "../../../routes/paths";
// assets
import { DocIllustration } from "../../../assets";
import googlePay from "src/images/googleplay.png";
import appStore from "src/images/astore.png";
import Image from "src/components/Image";
import Ternary from "src/components/ternary";
// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user, isAdmin } = useAuth();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 4,
        pb: 5,
        width: 1,
        textAlign: "center",
        display: "block",
        mt: 2,
      }}
    >
      {/* <DocIllustration sx={{ width: 1 }} /> */}
      <Ternary
        when={!isAdmin}
        then={
          <Box
            sx={{
              display: "grid",
              columnGap: 2,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              },
            }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.sparissimo"
              target="_blank"
            >
              <Image src={googlePay} sx={{ width: 80 }} />
            </a>
            <a
              href="https://apps.apple.com/us/app/sparissimo/id6452722686"
              target="_blank"
            >
              <Image src={appStore} sx={{ width: 80 }} />
            </a>
          </Box>
        }
      />
    </Stack>
  );
}
